@font-face {
  font-family: 'AlumniSans';
  font-weight: normal;
  font-style : italic;
  src: url('../public/files/project/fonts/AlumniSans-MediumItalic.woff') format('woff'),
       url('../public/files/project/fonts/AlumniSans-MediumItalic.ttf')  format('ttf');
}
@font-face {
  font-family: 'AlumniSans-MediumItalic';
  font-weight: normal;
  font-style : italic;
  src: url('../public/files/project/fonts/AlumniSans-MediumItalic.woff') format('woff'),
       url('../public/files/project/fonts/AlumniSans-MediumItalic.ttf')  format('ttf');
}

@font-face {
  font-family: 'AlumniSans-SemiBold';
  font-weight: bold;
  font-style : normal;
  src: url('../public/files/project/fonts/AlumniSans-SemiBold.woff') format('woff'),
       url('../public/files/project/fonts/AlumniSans-SemiBold.ttf')  format('ttf');
}

@font-face {
  font-family: 'AlumniSans-Bold';
  font-weight: bolder;
  font-style : regular;
  src: url('../public/files/project/fonts/AlumniSans-Bold.woff') format('woff'),
       url('../public/files/project/fonts/AlumniSans-Bold.ttf') format('ttf');
}


@font-face {
  font-family: 'montserrat';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/Montserrat-Regular.woff') format('woff'),
       url('../public/files/project/fonts/Montserrat-Regular.ttf')  format('ttf');
}

@font-face {
  font-family: 'montserrat';
  font-weight: bold;
  font-style : regular;
  src: url('../public/files/project/fonts/Montserrat-Bold.woff') format('woff'),
       url('../public/files/project/fonts/Montserrat-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'project';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/project.woff') format('woff'),
       url('../public/files/project/fonts/project.ttf') format('ttf');
}


.icon-font {
  font-family: 'project';
}

.title-font,
.content-font {
  font-family: 'montserrat';
}

.icon-font {
  font-family: 'project';
}

input, .generic-input, ::-webkit-input-placeholder {
  @extend .content-font;
}